import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import RichContentStructuredText from "../richContentStructuredText"
import { ChevronLeft, ChevronRight } from "react-feather"
import { GatsbyImage } from "gatsby-plugin-image"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])
// End swiper

const ImageGallery = ({ images }) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  // console.log(image)
  return (
    <Container
      variant="fw"
      sx={{ height: "100vh", position: "relative", height: "100%" }}
    >
      <Grid
        columns={[1, 1, 1, "calc(50vw - 640px) 1fr"]}
        gap={[0, 0, 0]}
        sx={{
          py: [0, 7, 7, 7],
          pb: [6, 7, 7, 7],
          px: [0, 0, 0],
          position: "relative",
        }}
      >
        <Box></Box>
        <Box>
          <Box>
            <Swiper
              spaceBetween={16}
              autoHeight={true}
              grabCursor={true}
              centeredSlidesBounds={true}
              navigation={{ prevEl, nextEl }}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                },
                640: {
                  slidesPerView: 2.2,
                },
                1020: {
                  slidesPerView: 3.2,
                },
              }}
              id={"mySwiper imageGallery"}
            >
              {images.map((image, index) => (
                <SwiperSlide key={image.originalId}>
                  <StyledImage image={image} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          <Flex sx={{ alignItems: "center" }}>
            <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: ["-2px"] }}>
              <Flex
                sx={{
                  p: [2],
                  border: "2px solid",
                  borderColor: "dark",
                  cursor: "pointer",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <ChevronLeft />
              </Flex>
            </Box>
            <Box as="div" ref={node => setNextEl(node)}>
              <Flex
                variant="svg.arrow.dark"
                sx={{
                  p: [2],
                  border: "2px solid",
                  borderColor: "dark",
                  cursor: "pointer",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <ChevronRight />
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Grid>
    </Container>
  )
}

const StyledImage = ({ image }) => (
  <>
    <Box
      sx={{
        mb: 2,
        ".gatsby-image-wrapper": {
          width: "100%",
          img: {
            height: "auto",
            width:"100%",
          },
        },
      }}
    >
      <GatsbyImage image={image.gatsbyImageData} alt={image.gatsbyImageData} />
    </Box>
    <Box>
      <Text variant="caption">{image.title}</Text>
    </Box>
  </>
)

export default ImageGallery
